@import colours
html
    padding: 0
    margin: 0
body
    padding: 0
    margin: 0
    overflow-x: hidden
    background-color: #fbfbfb !important

    *
        box-sizing: border-box

    a
        text-decoration: none!important

    .secondary-button
        color: $white
        background-color: $green

        &:hover
            color: $white
            background-color: $hoverGreen

    .navbar__wrapper
        min-height: 5rem
        max-height: 5rem
        padding: 1rem 9vw
        .navbar
            .nav-link
                color: #00000080
                position: relative
                font-weight: 500
                &:hover
                    cursor: pointer

            & :after
                content: ""
                position: absolute
                bottom: 0
                left: 50%
                transform: translateX(-50%)
                width: 2rem
                height: 3px
                background-color: $navbarBlue
                opacity: 0
                transition: all .3s cubic-bezier(.165,.84,.44,1)

        .nav-link.active:after
            opacity: 1

    main
        margin-bottom: 10vh
        .home
            margin: 0 5vw 5vh
            .upload__container
                background-color: $backgroundGrey !important
                .upload__img
                    img
                        max-width: 100%
                        height: auto
            .thumbnail__container
                max-height: 100%
                max-width: 100%
                img
                    max-height: 80%
                    max-width: 80%
        .result
            margin: 5vh 5vw !important
            max-width: 90vw
            max-height: max-content
            .thumbnail__container
                .thumbnail__container
                    position: relative
                .thumbnail-buttons
                    position: absolute
                    z-index: 2
                    opacity: 0.4
                    &:hover
                        opacity: 1
                img
                    max-width: 100%
                    height: auto
        .developer
            margin: 5vh 5vw !important
        .developer-container
            .developer-content
                margin: 3rem 0
                .developer-content-header
                    letter-spacing: $letterSpacing
